// extracted by mini-css-extract-plugin
export var imagePanels = "project-module--image-panels--3NJ3J";
export var imagePanelImg = "project-module--image-panel-img--12sHG";
export var imagePanelItem = "project-module--image-panel-item--o9oxY";
export var subtitle = "project-module--subtitle--Z1nwj";
export var tag = "project-module--tag--3nB6Y";
export var printPres = "project-module--print-pres--1kkaJ";
export var galleryImages = "project-module--gallery-images--1FwVK";
export var description = "project-module--description--2V9T3";
export var details = "project-module--details--WzHaG";
export var lineBreak = "project-module--line-break--1RVFh";
export var textPres = "project-module--text-pres--1uKcX";
export var mobile = "project-module--mobile--2Dj4D";
export var desktop = "project-module--desktop--22RWa";