import React from 'react'
import BlockContent from "@sanity/block-content-to-react";
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import Head from '../components/head'
import Layout from '../components/layout'
import * as ProjectStyles from '../components/project.module.css'

export const query = graphql`
  {
    allSanityArtwork(sort: {fields: _createdAt, order: DESC}) {
      edges {
        node {
          title
          titleImg {
            asset {
              gatsbyImageData(width:800)
            }
          }
          titleTxt
          medium
          description
          slug {
            current
          }
          tag {
            tagName
          }
          img {
            asset {
                gatsbyImageData(width: 480)                
            }
          }
          _rawText
        }
      }
    }
  }
`;

const Artworks = ({ data }) => {
    console.log( data );
    const artworkData = data.allSanityArtwork.edges;

    return(
        <Layout>
            <Head title="Artwork"/>
            <h1>Artwork</h1>
            <ol className = { ProjectStyles.imagePanels } >
                {         
                   artworkData.map(( { node }) => (  
                       <li key = { node.slug.current }>
                        <div className={ProjectStyles.imagePanelItem}>
                           <h3>{node.title}</h3>
                           {/*<p className={ProjectStyles.tag}>tags: {node.tag[0].tagName}</p>*/}
    
                           <div className = { ProjectStyles.imagePanelImg}>
                           <Link to={`/project/${node.slug.current}`}>
                                <GatsbyImage image = { node.titleImg.asset.gatsbyImageData } alt={ node.title} />
                               </Link> 
                           </div>
                           <p className={ProjectStyles.subtitle}> {node.titleTxt}</p>
                          </div> 
                       </li>
                   )) 
                }
            </ol>
        </Layout>
    )
}

export default Artworks